<template>
  <b-container fluid>
    <div>
      <div class="float-right py-3">
        <b-button v-if="isAdmin == '1'" variant="info" :to="{ name: 'create-user'}">
          Create
        </b-button>
      </div>
      <div class="clearfix"></div>

      <br/>
      <b-col md="12" class="my-1">
        <b-table
          ref="table"
          id="my-table"
          :fields="fields"
          :filter="searchByEmail"
          :items="users"
          :busy.sync="isBusy"
          :per-page="perPage"
          :current-page="page"
          @sort-changed="sortingChanged"
          small
          borderless
          hover
        >
          <template #head(email)="data">
            <span class="text-info">{{ data.label }}</span>
          </template>
          <template #head(first_name)="data">
            <span class="text-info">{{ data.label }}</span>
          </template>
          <template #head(last_name)="data">
            <span class="text-info">{{ data.label }}</span>
          </template>
          <template #head(is_admin)="data">
            <span class="text-info">{{ data.label }}</span>
          </template>
          <template #head(actions)="data">
            <span class="text-info">{{ data.label }}</span>
          </template>
          <template #cell(actions)="row" class="mb-1">
            <b-button variant="success" :to="{ name: 'update-user', params: {uuid: row.item.uuid}}">Edit</b-button>
            &nbsp;
            <b-button @click="showDeleteUserModal(row.item.uuid,  row.item.email, $event.target)">Delete</b-button>
          </template>
        </b-table>
      </b-col>

      <b-modal :id="infoDeleteUserModal.id" :uuid="infoDeleteUserModal.uuid" :title="infoDeleteUserModal.title"
               cancel-title="No" ok-title="Yes"
               ok-variant="danger" @hide="resetDeleteUserModal" @ok="deleteUserModal">
        {{ infoDeleteUserModal.content }}
      </b-modal>
    </div>
  </b-container>
</template>

<script>

import {mapActions, mapGetters} from "vuex";

export default {
  data() {
    return {
      items: [
        {
          text: 'Users',
          active: true
        }
      ],
      fields: [
        {
          key: 'email',
        },
        {
          key: 'first_name',
        },
        {
          key: 'last_name',
        },
        {
          key: 'actions',
          label: 'Actions',
        }
      ],
      pageOptions: [10, 20, 30],
      perPage: 10,
      page: 1,
      total: 0,
      searchByEmail: '',
      searchByLastName: '',
      striped: true,
      filter: false,
      isBusy: false,
      sortBy: '',
      sortDirection: '',
      infoDeleteUserModal: {
        id: 'info-modal',
        title: '',
        content: ''
      },
      isAdmin: "0"
    }
  },
  mounted() {
    this.isAdminUser()
  },
  methods: {
    ...mapActions(['getUsers', 'deleteUser']),
    ...mapGetters(['getUsersState', 'getTotalUsers', 'getUsersPage']),
    isAdminUser() {
        this.isAdmin = JSON.parse(localStorage.getItem('user')).is_admin
    },
    getRequestParams(searchByEmail, searchByLastName, page, perPage, sortBy, sortDirection) {
      let params = {};

      if (searchByEmail) {
        params["searchByEmail"] = searchByEmail;
      }

      if (searchByLastName) {
        params["searchByLastName"] = searchByLastName;
      }

      if (page) {
        params["page"] = page;
      }

      if (perPage) {
        params["perPage"] = perPage;
      }

      if (sortBy) {
        params["sortBy"] = sortBy;
      }

      if (sortDirection) {
        params["sortDirection"] = sortDirection;
      }

      return params;
    },
    users() {

      const params = this.getRequestParams(
        this.searchByEmail,
        this.searchByLastName,
        this.page,
        this.perPage,
        this.sortBy,
        this.sortDirection
      );

      return this.getUsers(params)
        .then((response) => {
          this.total = response.data.total
          return response.data.users
        })
    },
    sortingChanged(ctx) {

      this.sortBy = ctx.sortBy
      this.sortDirection = ctx.sortDesc ? 'desc' : 'asc'
      const params = this.getRequestParams(
        this.searchByEmail,
        this.searchByLastName,
        this.page,
        this.perPage,
        this.sortBy,
        this.sortDirection
      );

      return this.getUsers(params)
        .then((response) => {
          this.total = response.data.total
          return response.data.users
        })
    },
    handlePageChange(value) {
      this.page = value
      const params = this.getRequestParams(
        this.searchByEmail,
        this.searchByLastName,
        value,
        this.perPage,
        this.sortBy,
        this.sortDirection
      );

      return this.getUsers(params)
        .then((response) => {
          this.total = response.data.total
          return response.data.users
        })
    },
    handlePageSizeChange(value) {

      const params = this.getRequestParams(
        this.searchByEmail,
        this.searchByLastName,
        1,
        value,
        this.sortBy,
        this.sortDirection
      );

      return this.getUsers(params)
        .then((response) => {
          this.total = response.data.total
          return response.data.users
        })
    },
    handleFilterChange() {
      const params = this.getRequestParams(
        this.searchByEmail,
        this.searchByLastName,
        1,
        this.perPage,
        this.sortBy,
        this.sortDirection
      );

      return this.getUsers(params)
        .then((response) => {
          this.total = response.data.total
          return response.data.users
        })
    },
    showDeleteUserModal(uuid, email, button) {
      this.infoDeleteUserModal.uuid = uuid
      this.infoDeleteUserModal.title = `Confirmation`
      this.infoDeleteUserModal.content = `Are you sure you want to delete the user "${email}"?`
      this.$root.$emit('bv::show::modal', this.infoDeleteUserModal.id, button)
    },
    resetDeleteUserModal() {
      this.infoDeleteUserModal.uuid = ''
      this.infoDeleteUserModal.title = ''
      this.infoDeleteUserModal.content = ''
    },
    deleteUserModal() {
      this.deleteUser(this.infoDeleteUserModal.uuid)
      this.$refs.table.refresh();
    },
  }
}
</script>
<style>
.breadcrumb {
  background-color: #d1ecf1;
}
</style>
